
.breadcrumb {
  margin-bottom: 0;
}

.breadcrumb-item {

  + .breadcrumb-item::before {
    padding: 0;
    margin: 0 0.75rem;
    width: 4px;
    height: 4px;
    border-right: 1px solid $breadcrumb-divider-color;
    border-top: 1px solid $breadcrumb-divider-color;
    vertical-align: middle;
    transform: rotate(45deg);
  }

  a {
    color: $color-text-light;

    &:hover {
      color: $color-primary;
    }
  }

}
