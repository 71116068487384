
// Import the plugin style.
//
@import "~et-line/style";

// Change font path.
//
@font-face {
  font-family: 'et-line';
  src: url('../fonts/et-line.eot');
  src: url('../fonts/et-line.eot?#iefix') format('embedded-opentype'),
       url('../fonts/et-line.woff') format('woff'),
       url('../fonts/et-line.ttf') format('truetype'),
       url('../fonts/et-line.svg#et-line') format('svg');
  font-weight: normal;
  font-style: normal;
}
