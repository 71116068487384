

.topbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid $color-divider-light;
  height: 40px;
  z-index: $zindex-topbar;

  > .container,
  > .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: inherit;
  }

  &.text-white {
    border-bottom-color: rgba(#fff, 0.05);
  }

  body:not(.body-scrolled) &.d-lg-flex ~ .navbar {
    top: 40px;
  }

}


.topbar-divider {
  display: inline-block;
  height: 12px;
  width: 1px;
  background-color: rgba(127,127,127,0.15);
  margin: 0 1rem;
  margin-bottom: 2px;
  vertical-align: middle;
}
