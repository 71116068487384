
.accordion {

  .card {
    background-color: #fff;
    border: 1px solid $color-divider-light;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .card-title {
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $color-bg-lighter;
  }

  .card-title a {
    display: block;
    padding: 16px 33px;
    color: $color-title;

    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border-left: 1px solid $color-text;
      border-top: 1px solid $color-text;
      margin-right: 20px;
      margin-left: -33px;
      margin-bottom: 3px;
      transform: rotate(45deg);
      transition: .2s linear;
    }

    &.collapsed::before {
      transform: rotate(-135deg);
    }
  }

}


//----------------------------------------------------/
// Connected
//----------------------------------------------------/
.accordion-connected {


  .card {
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid $color-divider-light;

    &:last-child {
      border-bottom: 0;
    }
  }


  .card-title {
    border-bottom: 0;
    background-color: #fff;

    a {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
