
//----------------------------------------------------/
// Border
//----------------------------------------------------/
.bt-1, .border-top-1    { border-top: 1px solid $color-divider-light; }
.bl-1, .border-left-1   { border-left: 1px solid $color-divider-light; }
.br-1, .border-right-1  { border-right: 1px solid $color-divider-light; }
.bb-1, .border-bottom-1 { border-bottom: 1px solid $color-divider-light; }

.bt-0, .border-top-0    { border-top-width: 0; }
.bl-0, .border-left-0   { border-left-width: 0; }
.br-0, .border-right-0  { border-right-width: 0; }
.bb-0, .border-bottom-0 { border-bottom-width: 0; }

.b-1 { border: 1px solid $color-divider-light; }
.b-0 { border-width: 0; }

//----------------------------------------------------/
// Border radius
//----------------------------------------------------/
.rounded-md { border-radius: 0.5rem !important; }
.rounded-lg { border-radius: 0.75rem !important; }
.rounded-xl { border-radius: 1rem !important; }

.border-glass,
.border-glass-1 { border-color: rgba(#fff, 0.35) !important; }
.border-glass-2 { border-color: rgba(#fff, 0.25) !important; }
.border-glass-3 { border-color: rgba(#fff, 0.15) !important; }
