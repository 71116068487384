
.nav {

  &.nav-center {
    @extend .justify-content-center;
  }

  &.flex-column {
    .nav-link {
      padding: 2px 0;

      &:first-child {
        padding-top: 0;
      }
    }
  }

}


.nav-link {
  color: $color-text;
  font-weight: 400;
}

.nav-link.active,
.nav-item.show .nav-link {
  color: $color-primary;
}

.nav-divider {
  display: block;
  margin: 0.5rem 1.125rem;
  height: 1px;
  background-color: rgba(127,127,127,0.1);
}


//----------------------------------------------------/
// Uppercase
//----------------------------------------------------/
.nav-uppercase > .nav-link,
.nav-uppercase > .nav-item > .nav-link {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.85em;
  word-spacing: 2px;
}


//----------------------------------------------------/
// Bold
//----------------------------------------------------/
.nav-bold {

  .nav-item {
    font-weight: 600;
  }

  .nav-link {
    font-weight: 600;
    //text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.85em;
    word-spacing: 2px;
  }

}


//----------------------------------------------------/
// Bolder
//----------------------------------------------------/
.nav-bolder {

  .nav-item {
    font-weight: 700;
  }

  .nav-link {
    font-weight: 700;
    //text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.8em;
    word-spacing: 2px;
  }

}


//----------------------------------------------------/
// Lead
//----------------------------------------------------/
.nav-lead .nav-link {
  font-size: 1rem;
}



//----------------------------------------------------/
// Pills
//----------------------------------------------------/
.nav-pills {

  &,
  &.flex-column {
    .nav-link {
      padding: 3px $nav-link-padding-x;
    }
  }

}



//----------------------------------------------------/
// Slash
//----------------------------------------------------/
.nav-slash {

  .nav-item {
    display: inline-flex;
    align-items: center;

    &::after {
      content: '/';
      font-weight: 400;
      opacity: 0.6;
    }

    &:last-child::after {
      display: none;
    }
  }

}



//----------------------------------------------------/
// Dot
//----------------------------------------------------/
.nav-dot {

  .nav-item {
    display: inline-flex;
    align-items: center;

    &::after {
      content: "\2022";
      font-weight: 100;
      opacity: 0.75;
    }

    &:last-child::after {
      display: none;
    }
  }

}


//----------------------------------------------------/
// Iconic
//----------------------------------------------------/
.nav-iconic {

  .nav-link {
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    i {
      margin-bottom: 1rem;
    }
  }
}


//----------------------------------------------------/
// Utility
//----------------------------------------------------/
.nav-page {
  display: block;
  position: fixed;
  top: 50%;
  right: 20px;
  width: 34px;
  border-radius: 10rem;
  padding: 7px 0;
  text-align: center;
  background-color: $color-bg-dark;
  opacity: 0;
  transform: translateY(-50%);
  transition: .5s ease-out;

  .body-scrolled & {
    opacity: .5;
  }

  &:hover {
    opacity: .65;
  }

  &.nav-page-left {
    right: auto;
    left: 20px;
  }

  .nav-item {
    position: relative;
    display: block;
    padding: 0;
  }

  .nav-link {
    display: inline-block;
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 4px;
    background-color: #fff;
    opacity: .5;

    &:hover,
    &.active {
      opacity: 1;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}


//----------------------------------------------------/
// Dark
//----------------------------------------------------/
.nav-dark {

  .nav-link {
    color: rgba($color-text, 0.65);
  }

  .nav-link:hover,
  .nav-link.active,
  .nav-item.show .nav-link {
    color: $color-title;
  }

  .nav-link.disabled {
    color: $nav-link-disabled-color;
  }
}


//----------------------------------------------------/
// Light
//----------------------------------------------------/
.nav-light {

  .nav-link {
    color: rgba(#fff, 0.65);
  }

  .nav-link:hover,
  .nav-link.active,
  .nav-item.show .nav-link {
    color: #fff;
  }

  .nav-link.disabled {
    color: rgba(#fff, 0.5);
  }

  &.nav-pills {
    .nav-link.active,
    .show > .nav-link {
      background-color: rgba(#fff, 0.25);
    }
  }
}


//----------------------------------------------------/
// Utility
//----------------------------------------------------/
.nav-trim {
  .nav-link:first-child {
    padding-left: 0;
  }

  .nav-link:last-child {
    padding-right: 0;
  }
}
